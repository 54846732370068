import React, { useContext } from 'react';
import { ImageContext } from '../ImageContext';
import { PiCpuLight, PiGraphicsCardLight, PiMemoryLight } from "react-icons/pi";
import { BsHdd } from "react-icons/bs";
import { Link } from 'react-router-dom';
import './Card.css'

const Card = ({ option }) => {
  const { images, setImage } = useContext(ImageContext);
  const currentImage = images[option.id] || option.images.default;

  return (
    <div className="card-wrapper" style={{width:"100%", display:"inline-block"}}>
      <div className="card">
        <div className="color-dots">
          <div className="color-dot white" onClick={() => setImage(option.id, option.images.white)}></div>
          <div className="color-dot black" onClick={() => setImage(option.id, option.images.black)}></div>
        </div>
        <img src={currentImage} alt={option.title} className="card-image" />
        <div className="card-content">
          <h3 className='title'>{option.title}</h3>
          <span className='spec'><PiCpuLight /><p>{option.cpu}</p></span>
          <span className='spec'><PiGraphicsCardLight /><p>{option.gpu}</p></span>
          <span className='spec'><PiMemoryLight /><p>{option.ram}</p></span>
          <span className='spec' style={{display: option.ssd ? "inline" : "none"}}><BsHdd /><p>{option.ssd}</p></span>
          <span className='spec' style={{display: option.hdd ? "inline" : "none"}}><BsHdd /><p>{option.hdd}</p></span>
        </div>
        <p className='price'>${option.price}</p>
        <Link to={`/item/${option.id}`}><button className="buy-now-button">Buy Now</button></Link>
      </div>
    </div>
  );
};

export default Card;