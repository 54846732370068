import React, { createContext, useState } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item, image) => {
    const newItem = structuredClone(item)
    newItem.images.selected = image;
    setCart((prevCart) => [...prevCart, newItem]);
  };

  const removeFromCart = (item) => {
    setCart([...cart.slice(0, cart.indexOf(item)), ...cart.slice(cart.indexOf(item) + 1)])
  }

  const getPrice = () => {
    let price = 0;
    for (let i = 0; i < cart.length; i++) {
      price += parseInt(cart[i].price)
    }
    return price.toFixed(2)
  }

  const cartItemCount = cart.length;

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, getPrice, cartItemCount }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };