import React from 'react';
import './ComingSoon.css';
import '../../index.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className='coming-soon'>Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;