export const BuildOptionList = [
  {
    id: 1,
    images: {
      black: 'nzxth5flowblack.png',
      white: 'nzxth5flowwhite.png',
      default: 'nzxth5flowwhite.png'
    },
    title: 'Essential',
    price: '749.00',
    cpu: "AMD Ryzen™ 5 3600",
    gpu: "AMD Radeon™ RX 580",
    ram: "16 GB DDR4, 3200 MT/s",
    ssd: "",
    hdd: "1 TB HDD, 210 MB/s",
    cpu_details: "AMD Ryzen™ 5 3600, 35 MB, 6 x 3.6 GHz",
    gpu_details: "Radeon RX 580, 8 GB DDR5",
    ram_details: "Corsair Vengeance, 16 GB (2 x 8 GB) DDR4, 3200 MT/s",
    ssd_details: "",
    hdd_details: "Seagate BarraCuda, 1 TB, 210 MB/s",
    case_details: "NZXT H5 Flow",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "ASRock B450M/ac R2.0, WIFI 5",
    psu_details: "Thermaltake Smart, 500 W, 80+ Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
  {
    id: 2,
    images: {
      black: 'nzxth5flowblack.png',
      white: 'nzxth5flowwhite.png',
      default: 'nzxth5flowblack.png'
    },
    title: 'Performance',
    price: '999.00',
    cpu: "AMD Ryzen™ 5 5600X",
    gpu: "AMD Radeon™ RX 6600",
    ram: "16 GB DDR4, 3200 MT/s",
    ssd: "",
    hdd: "1 TB HDD, 210 MB/s",
    cpu_details: "AMD Ryzen™ 5 5600X, 35 MB, 6 x 3.7 GHz",
    gpu_details: "Radeon RX 6600, 8 GB GDDR6",
    ram_details: "Corsair Vengeance, 16 GB (2 x 8 GB) DDR4, 3200 MT/s",
    ssd_details: "",
    hdd_details: "Seagate BarraCuda, 1 TB, 210 MB/s",
    case_details: "NZXT H5 Flow",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "MSI PRO B550M-VC WIFI, WIFI 6E",
    psu_details: "Thermaltake Smart, 500 W, 80+ Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
  {
    id: 3,
    images: {
      black: 'nzxth5flowblack.png',
      white: 'nzxth5flowwhite.png',
      default: 'nzxth5flowwhite.png'
    },
    title: 'Elite',
    price: '1499.00',
    cpu: "Intel® Core™ i7-13700KF",
    gpu: "NVIDEA® GeForce RTX™ 4060",
    ram: "16 GB DDR5, 5200 MT/s",
    ssd: "1 TB M.2 SSD, 7450 MB/s",
    hdd: "",
    cpu_details: "Intel® Core™ i7-13700KF, 54 MB, 8 x 3.4 GHz + 8 x 2.5 GHz",
    gpu_details: "GeForce RTX 4060, 8 GB GDDR6",
    ram_details: "Corsair Vengeance, 16 GB (2 x 8 GB) DDR5, 5200 MT/s",
    ssd_details: "Samsung 990 Pro M.2 SSD, 1 TB, 7450 MB/s",
    hdd_details: "",
    case_details: "NZXT H5 Flow",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "MSI B760 GAMING PLUS WIFI, WIFI 6E",
    psu_details: "Thermaltake Toughpower GX2, 600 W, 80+ Gold Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
  {
    id: 4,
    images: {
      black: 'nzxth7eliteblack.png',
      white: 'nzxth7elitewhite.png',
      default: 'nzxth7eliteblack.png'
    },
    title: 'Challenger',
    price: '2249.00',
    cpu: "Intel® Core™ i7-14700KF",
    gpu: "NVIDEA® GeForce RTX™ 4070 SUPER",
    ram: "32 GB DDR5, 5600 MT/s",
    ssd: "1 TB M.2 SSD, 7450 MB/s",
    hdd: "",
    cpu_details: "Intel® Core™ i7-14700KF, 61 MB, 12 x 3.4 GHz + 8 x 2.5 GHz",
    gpu_details: "GeForce RTX 4070 SUPER, 12 GB GDDR6X",
    ram_details: "Corsair Vengeance, 32 GB (2 x 16 GB) DDR5, 5600 MT/s",
    ssd_details: "Samsung 990 Pro M.2 SSD, 1 TB, 7450 MB/s",
    hdd_details: "",
    case_details: "NZXT H7 Elite",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "Gigabyte Z790 EAGLE, WIFI 6E",
    psu_details: "SeaSonic FOCUS Plus Platinum, 750 W, 80+ Platinum Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
  {
    id: 5,
    images: {
      black: 'nzxth7eliteblack.png',
      white: 'nzxth7elitewhite.png',
      default: 'nzxth7elitewhite.png'
    },
    title: 'Champion',
    price: '2999.00',
    cpu: "Intel® Core™ i9-14900KF",
    gpu: "NVIDEA® GeForce RTX™ 4080 SUPER",
    ram: "32 GB DDR5, 5600 MT/s",
    ssd: "2 TB M.2 SSD, 7450 MB/s",
    hdd: "",
    cpu_details: "Intel® Core™ i9-14900KF, 68 MB, 16 x 3.2 GHz + 8 x 2.4 GHz",
    gpu_details: "GeForce RTX 4080 SUPER, 16 GB GDDR6X",
    ram_details: "Corsair Vengeance, 32 GB (2 x 16 GB) DDR5, 5600 MT/s",
    ssd_details: "Samsung 990 Pro M.2 SSD, 2 TB, 7450 MB/s",
    hdd_details: "",
    case_details: "NZXT H7 Elite",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "MSI PRO Z790-A MAX WIFI, WIFI 7",
    psu_details: "SeaSonic FOCUS Plus Platinum, 850 W, 80+ Platinum Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
  {
    id: 6,
    images: {
      black: 'nzxth7eliteblack.png',
      white: 'nzxth7elitewhite.png',
      default: 'nzxth7eliteblack.png'
    },
    title: 'GOAT',
    price: '3999.00',
    cpu: "Intel® Core™ i9-14900KF",
    gpu: "NVIDEA® GeForce RTX™ 4090",
    ram: "64 GB DDR5, 5600 MT/s",
    ssd: "2 TB M.2 SSD, 7450 MB/s",
    hdd: "",
    cpu_details: "Intel® Core™ i9-14900KF, 68 MB, 16 x 3.2 GHz + 8 x 2.4 GHz",
    gpu_details: "GeForce RTX 4090, 24 GB GDDR6X",
    ram_details: "Corsair Vengeance, 64 GB (2 x 32 GB) DDR5, 5600 MT/s",
    ssd_details: "Samsung 990 Pro M.2 SSD, 2 TB, 7450 MB/s",
    hdd_details: "",
    case_details: "NZXT H7 Elite",
    fan_details: "Cooler Master Hyper 212 Black",
    board_details: "MSI PRO Z790-A MAX WIFI, WIFI 7",
    psu_details: "be quiet! Dark Power 13, 1000 W, 80+ Titanium Certified",
    os_details: "Windows 11 Home",
    warranty_details: "2 Year Limited Warranty",
  },
]