// BuildOptions.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BuildOptions.css';
import { ImageProvider } from './ImageContext.js';
import Card from './card/Card.js';
import { BuildOptionList } from './BuildOptionList.js';

const buildOptions = BuildOptionList;

const BuildOptions = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 960) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 1340) {
        setSlidesToShow(3);
      } else if (window.innerWidth < 1820) {
        setSlidesToShow(4);
      } else if (window.innerWidth < 2300) {
        setSlidesToShow(5);
      } else {
        setSlidesToShow(6);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const totalSlides = buildOptions.length;


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 2780,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1820,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: currentSlide < totalSlides - slidesToShow ? <CustomNextArrow /> : null,
    prevArrow: currentSlide > 0 ? <CustomPrevArrow /> : null,
  };

  return (
    <ImageProvider>
      <div className="build-options-container">
        <Slider {...settings}>
          {buildOptions.map((option) => (
            <Card key={option.id} option={option} />
          ))}
        </Slider>
      </div>
    </ImageProvider>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', marginRight:'8px', zIndex: 1 }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style={{ width: '30px', height: '30px', color: 'black' }}
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style={{ width: '30px', height: '30px', color: 'black' }}
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
};

export default BuildOptions;
