import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar.js';
import MobileNavbar from './components/navbar/MobileNavbar.js';
import ComingSoon from './components/comingSoon/ComingSoon.js';
import Warranty from './components/support/warranty/Warranty.js';
import BuildOptions from './components/offers/buildOptions/BuildOptions.js';
import HomePage from './components/HomePage.js';
import ItemDetail from './components/offers/itemDetail/itemDetail.js';
import Cart from './components/offers/cart/Cart.js';
import { CartProvider } from './components/offers/cart/CartContext.js';
import useWindowDimensions from './components/WindowDimensions.js';

function App() {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768; 

  return (
    <CartProvider>
      <Router>
        <div className="App">
          {isMobile ? <MobileNavbar /> : <Navbar />}
          <Routes>
            <Route path="/coming-soon" element={<ComingSoon/>}/>
            <Route path="/warranty" element={<Warranty/>}/>
            <Route path="/build-options" element={<BuildOptions/>}/>
            <Route path="/item/:id" element={<ItemDetail/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/" element={<HomePage/>}/>
          </Routes>
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
