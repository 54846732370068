import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 
import { BsCart3 } from "react-icons/bs";
// import { checkout } from '../../requests'
import { CartContext } from '../offers/cart/CartContext';

const Navbar = () => {
  const { cartItemCount } = useContext(CartContext);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="navbar-logo">
          <Link to="/">
            <img src='/pcgoatonlyblack.png' className='logo' alt='PCGOAT'></img>
          </Link>
        </div>
        <ul className="navbar-menu">
          <Link to='build-options'>
            <li className="navbar-item" id='first-item'>
              <div className="dropdown">
                <p className="dropbtn">Offers</p>
                <div className="dropdown-content">
                  <Link to="/build-options">Prebuilt PCs</Link>
                  <Link to="/coming-soon">Custom PCs</Link>
                </div>
              </div>
            </li>
          </Link>
          <Link to='/coming-soon'>
            <li className="navbar-item">
              <div className="dropdown">
                <p className="dropbtn">Community</p>
                <div className="dropdown-content">
                  <Link to="/coming-soon">Discord</Link>
                  <Link to="/coming-soon">Events</Link>
                </div>
              </div>
            </li>
          </Link>
          <Link to='/coming-soon'>
            <li className="navbar-item">
              <div className="dropdown">
                <p className="dropbtn">Support</p>
                <div className="dropdown-content">
                  <Link to="/warranty">Warranty</Link>
                  <Link to="/coming-soon">Contact Us</Link>
                </div>
              </div>
            </li>
          </Link>
        </ul>
      </div>
      <div className="navbar-right">
        <Link to='/cart' className="cart">
          <BsCart3 
            // onClick={() => { 
            // checkout([{
            //   name: 'GOAT',
            //   price: 1.99, // in dollars
            //   quantity: 1
            // }])
            // }}
          />
          {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>}
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;