import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsCart3 } from "react-icons/bs";
import { CartContext } from '../offers/cart/CartContext';

import './MobileNavbar.css';

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartItemCount } = useContext(CartContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mobile-navbar">
      <div className="navbar-header">
        <div className='header-left'>
          <div className='hamburger-container'>
            <div className="hamburger" onClick={toggleMenu}>
              <div className={`line ${isOpen ? 'open' : ''}`}></div>
              <div className={`line ${isOpen ? 'open' : ''}`}></div>
              <div className={`line ${isOpen ? 'open' : ''}`}></div>
            </div>
          </div>
          <div className="logo">
            <Link to="/">
              <img src='/pcgoatonlyblack.png' className='logo' alt='PCGOAT'></img>
            </Link>
          </div>
        </div>
        <div className="navbar-right">
          <Link to='/cart' className="cart">
            <BsCart3 
              // onClick={() => { 
              // checkout([{
              //   name: 'GOAT',
              //   price: 1.99, // in dollars
              //   quantity: 1
              // }])
              // }}
            />
            {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>}
          </Link>
        </div>
      </div>
      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <Link className='dropdown-header' to="/build-options" onClick={toggleMenu}>Offers</Link>
          <div className='dropdown-section'>
            <li>
              <Link to="/build-options" onClick={toggleMenu}>Prebuilt PCs</Link>
            </li>
            <li className='last-li'>
              <Link to="/coming-soon" onClick={toggleMenu}>Custom PCs</Link>
            </li>
          </div>
          <Link className='dropdown-header' to="/coming-soon" onClick={toggleMenu}>Community</Link>
          <div className='dropdown-section'>
            <li>
              <Link to="/coming-soon" onClick={toggleMenu}>Discord</Link>
            </li>
            <li className='last-li'>
              <Link to="/coming-soon" onClick={toggleMenu}>Events</Link>
            </li>
          </div>
          <Link className='dropdown-header' to="/coming-soon" onClick={toggleMenu}>Support</Link>
          <div className='dropdown-section last-dropdown-section'>
            <li>
              <Link to="/warranty" onClick={toggleMenu}>Warranty</Link>
            </li>
            <li className='last-li'>
              <Link to="/coming-soon" onClick={toggleMenu}>Contact Us</Link>
            </li>
          </div>
        </ul>
        <div className='dropdown-space'></div>
        <Link className='dropdown-log-in' to="/coming-soon" onClick={toggleMenu}>Log In</Link>
        <div className='dropdown-space'></div>
      </div>
    </div>
  );
};

export default MobileNavbar;