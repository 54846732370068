import React, { createContext, useState } from 'react';

const ImageContext = createContext()

const ImageProvider = ({ children }) => {
  const [images, setImages] = useState({});
  
  const setImage = (id, color) => {
    setImages((prevImages) => ({
      ...prevImages,
      [id]: color,
    }));
  };

  return (
    <ImageContext.Provider value={{ images, setImage }}>
      {children}
    </ImageContext.Provider>
  );
};

export { ImageContext, ImageProvider };