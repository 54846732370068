import React, { useContext } from 'react';
import { CartContext } from './CartContext';
import { PiTrash, PiPlus } from "react-icons/pi";
import useWindowDimensions from '../../WindowDimensions';
import './Cart.css';

const Cart = () => {
  const { cart, addToCart, removeFromCart, getPrice, cartItemCount } = useContext(CartContext);
  const { width } = useWindowDimensions();

  if (width > 1400) {
    return (
      <div className='ccart-page'>
        <div className="ccart">
          <div className='ccart-title'>
            <h1 className='your-cart'>Your Cart</h1>
            <h1 className='cart-item-count'>( {cartItemCount} )</h1>
          </div>
          {cart.length === 0 ? (
            <p className='empty-cart'>Your cart is empty</p>
          ) : (
            <ul>
              {cart.map((item, index) => (
                <li key={index}>
                  <div className='cleft-details'>
                    <img src={item.images.selected} className='cimage' alt='Computer'></img> 
                    <div className='ispecs cspecs'>
                      <h1 className='ititle ctitle'>{item.title}</h1>
                      <div className='cspec-list'>
                        <span className='cspec'><p>Case: {item.case_details}</p></span>
                        <span className='cspec'><p>Processor: {item.cpu_details}</p></span>
                        <span className='cspec'><p>CPU Cooler: {item.fan_details}</p></span>
                        <span className='cspec'><p>Graphics Card: {item.gpu_details}</p></span>
                        <span className='cspec'><p>Memory: {item.ram_details}</p></span>
                        <span className='cspec' style={{display: item.ssd_details ? "inline" : "none"}}><p>Solid State Drive: {item.ssd_details}</p></span>
                        <span className='cspec' style={{display: item.hdd_details ? "inline" : "none"}}><p>Hard Drive: {item.hdd_details}</p></span>
                        <span className='cspec'><p>Motherboard: {item.board_details}</p></span>
                        <span className='cspec'><p>Power Supply: {item.psu_details}</p></span>
                        <span className='cspec'><p>Operating System: {item.os_details}</p></span>
                        <span className='cspec'><p>Warranty: {item.warranty_details}</p></span>
                      </div>
                    </div>
                  </div>
                  <div className='cright-details'>
                    <div><PiPlus className='cplus' onClick={() => addToCart(item, item.images.selected)}/><PiTrash onClick={() => removeFromCart(item)}/></div>
                    <h1 className='iprice cprice'>${item.price}</h1> 
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className='ccheckout'>
          <h1 className='csummary'>Summary</h1>
          <div className='csubtotal'>
            <h3>Subtotal</h3>
            <p>${getPrice()}</p>
          </div>
          <div className='ctaxes'>
            <h3>Taxes</h3>
            <p>Calculated at checkout</p>
          </div>
          <div className='cshipping'>
            <h3>Subtotal</h3>
            <p>Calculated at checkout</p>
          </div>
          <h3 className='coupon-code'>Coupon Code:</h3>
          <div className='ccoupon'>
            <input></input>
          </div>
          <p className='apply-coupon'>Your coupon will be applied at checkout</p>
          <button disabled={cartItemCount === 0} className="ccheckout-button" onClick={() => {console.log("Hi")}}>Proceed to Checkout</button>
        </div>
      </div>
    );
  } else { 
    return (
      <div className='ccart-page'>
        <div className="ccart mccart">
          <div className='ccart-title'>
            <h1 className='your-cart'>Your Cart</h1>
            <h1 className='cart-item-count'>( {cartItemCount} )</h1>
          </div>
          {cart.length === 0 ? (
            <p className='empty-cart'>Your cart is empty</p>
          ) : (
            <ul>
              {cart.map((item, index) => (
                <li key={index}>
                  <div className='cleft-details'>
                    <img src={item.images.selected} className='cimage' alt='Computer'></img> 
                    <div className='ispecs cspecs'>
                      <h1 className='ititle ctitle'>{item.title}</h1>
                      <div className='cspec-list'>
                        <span className='cspec'><p>Case: {item.case_details}</p></span>
                        <span className='cspec'><p>Processor: {item.cpu_details}</p></span>
                        <span className='cspec'><p>CPU Cooler: {item.fan_details}</p></span>
                        <span className='cspec'><p>Graphics Card: {item.gpu_details}</p></span>
                        <span className='cspec'><p>Memory: {item.ram_details}</p></span>
                        <span className='cspec' style={{display: item.ssd_details ? "inline" : "none"}}><p>Solid State Drive: {item.ssd_details}</p></span>
                        <span className='cspec' style={{display: item.hdd_details ? "inline" : "none"}}><p>Hard Drive: {item.hdd_details}</p></span>
                        <span className='cspec'><p>Motherboard: {item.board_details}</p></span>
                        <span className='cspec'><p>Power Supply: {item.psu_details}</p></span>
                        <span className='cspec'><p>Operating System: {item.os_details}</p></span>
                        <span className='cspec'><p>Warranty: {item.warranty_details}</p></span>
                      </div>
                    </div>
                  </div>
                  <div className='cright-details'>
                    <div><PiPlus className='cplus' onClick={() => addToCart(item, item.images.selected)}/><PiTrash onClick={() => removeFromCart(item)}/></div>
                    <h1 className='iprice cprice'>${item.price}</h1> 
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className='ccheckout mccheckout'>
          <h1 className='csummary'>Summary</h1>
          <div className='csubtotal'>
            <h3>Subtotal</h3>
            <p>${getPrice()}</p>
          </div>
          <div className='ctaxes'>
            <h3>Taxes</h3>
            <p>Calculated at checkout</p>
          </div>
          <div className='cshipping'>
            <h3>Subtotal</h3>
            <p>Calculated at checkout</p>
          </div>
          <h3 className='coupon-code'>Coupon Code:</h3>
          <div className='ccoupon'>
            <input></input>
          </div>
          <p className='apply-coupon'>Your coupon will be applied at checkout</p>
          <button disabled={cartItemCount === 0} className="ccheckout-button" onClick={() => {console.log("Hi")}}>Proceed to Checkout</button>
        </div>
      </div>
    );
  } 
};

export default Cart;
