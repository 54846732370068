import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { BuildOptionList } from '../buildOptions/BuildOptionList';
import { PiCpuLight, PiGraphicsCardLight, PiMemoryLight, PiComputerTower, PiFan, PiWindowsLogo, PiHandshake,PiPower } from "react-icons/pi";
import { BsHdd, BsMotherboard } from "react-icons/bs";
import useWindowDimensions from '../../WindowDimensions';
import { CartContext } from '../cart/CartContext';
import './itemDetail.css'

const ItemDetail = () => {
  const { id } = useParams();
  const item = BuildOptionList.find((option) => option.id === parseInt(id));
  const [ image, setImage ] = useState(item.images.white)
  const { height, width } = useWindowDimensions();
  const { addToCart } = useContext(CartContext);

  const imgSize = Math.min(.8 * height, height - 40)
  const smallImgSizes = .1 * height;

  if (!item) {
    return <div>Item not found</div>;
  } 

  if (width > imgSize + smallImgSizes + 57) {
    return (
      <div className="item-detail">
        <div className='left-of-image'>
          <img src={`/${item.images.white}`} alt={item.title} style={{borderColor: image===item.images.white ? '#7627df' : '#000'}} onClick={() => setImage(item.images.white)} className="item-image-white" />
          <img src={`/${item.images.black}`} alt={item.title} style={{borderColor: image===item.images.black ? '#7627df' : '#000'}} onClick={() => setImage(item.images.black)} className="item-image-black" />
        </div>
        <img src={`/${image}`} alt={item.title} className="item-image" />
        <div className='ispecs'>
          <h1 className='ititle'>{item.title}</h1>
          <h1 className='iprice'>${item.price}</h1> 
          <div className='spec-list'>
            <span className='ispec-label'><p>Case</p></span>
            <span className='ispec'><PiComputerTower /><p>{item.case_details}</p></span>
            <span className='ispec-label'><p>Processor</p></span>
            <span className='ispec'><PiCpuLight /><p>{item.cpu_details}</p></span>
            <span className='ispec-label'><p>CPU Cooler</p></span>
            <span className='ispec'><PiFan /><p>{item.fan_details}</p></span>
            <span className='ispec-label'><p>Graphics Card</p></span>
            <span className='ispec'><PiGraphicsCardLight /><p>{item.gpu_details}</p></span>
            <span className='ispec-label'><p>Memory</p></span>
            <span className='ispec'><PiMemoryLight /><p>{item.ram_details}</p></span>
            <span className='ispec-label' style={{display: item.ssd_details ? "inline" : "none"}}><p>Solid State Drive</p></span>
            <span className='ispec' style={{display: item.ssd_details ? "inline" : "none"}}><BsHdd /><p>{item.ssd_details}</p></span>
            <span className='ispec-label' style={{display: item.hdd_details ? "inline" : "none"}}><p>Hard Drive</p></span>
            <span className='ispec' style={{display: item.hdd_details ? "inline" : "none"}}><BsHdd /><p>{item.hdd_details}</p></span>
            <span className='ispec-label'><p>Motherboard</p></span>
            <span className='ispec'><BsMotherboard /><p>{item.board_details}</p></span>
            <span className='ispec-label'><p>Power Supply</p></span>
            <span className='ispec'><PiPower /><p>{item.psu_details}</p></span>
            <span className='ispec-label'><p>Operating System</p></span>
            <span className='ispec'><PiWindowsLogo /><p>{item.os_details}</p></span>
            <span className='ispec-label'><p>Warranty</p></span>
            <span className='ispec'><PiHandshake /><p>{item.warranty_details}</p></span>
          </div>
          <div id='bottom-details'>
            <span className='ispec' id='components-different'><p>Components may vary from images shown.</p></span>
            <button className="add-to-cart-button" onClick={() => addToCart(item, image)}>Add to Cart</button>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="item-detail">
        <img src={`/${image}`} alt={item.title} className="item-image" />
        <div className='left-of-image' style={{width: width - 40, marginTop: '10px'}}>
          <img src={`/${item.images.white}`} alt={item.title} style={{borderColor: image===item.images.white ? '#7627df' : '#000'}} onClick={() => setImage(item.images.white)} className="item-image-white" />
          <img src={`/${item.images.black}`} alt={item.title} style={{borderColor: image===item.images.black ? '#7627df' : '#000', marginLeft: '4px'}} onClick={() => setImage(item.images.black)} className="item-image-black" />
        </div>
        <div className='ispecs' style={{paddingTop: '10px'}}>
          <h1 className='ititle'>{item.title}</h1>
          <h1 className='iprice'>${item.price}</h1>
          <div className='spec-list'>
            <span className='ispec-label'><p>Case</p></span>
            <span className='ispec'><PiComputerTower /><p>{item.case_details}</p></span>
            <span className='ispec-label'><p>Processor</p></span>
            <span className='ispec'><PiCpuLight /><p>{item.cpu_details}</p></span>
            <span className='ispec-label'><p>CPU Cooler</p></span>
            <span className='ispec'><PiFan /><p>{item.fan_details}</p></span>
            <span className='ispec-label'><p>Graphics Card</p></span>
            <span className='ispec'><PiGraphicsCardLight /><p>{item.gpu_details}</p></span>
            <span className='ispec-label'><p>Memory</p></span>
            <span className='ispec'><PiMemoryLight /><p>{item.ram_details}</p></span>
            <span className='ispec-label' style={{display: item.ssd_details ? "inline" : "none"}}><p>Solid State Drive</p></span>
            <span className='ispec' style={{display: item.ssd_details ? "inline" : "none"}}><BsHdd /><p>{item.ssd_details}</p></span>
            <span className='ispec-label' style={{display: item.hdd_details ? "inline" : "none"}}><p>Hard Drive</p></span>
            <span className='ispec' style={{display: item.hdd_details ? "inline" : "none"}}><BsHdd /><p>{item.hdd_details}</p></span>
            <span className='ispec-label'><p>Motherboard</p></span>
            <span className='ispec'><BsMotherboard /><p>{item.board_details}</p></span>
            <span className='ispec-label'><p>Power Supply</p></span>
            <span className='ispec'><PiPower /><p>{item.psu_details}</p></span>
            <span className='ispec-label'><p>Operating System</p></span>
            <span className='ispec'><PiWindowsLogo /><p>{item.os_details}</p></span>
            <span className='ispec-label'><p>Warranty</p></span>
            <span className='ispec'><PiHandshake /><p>{item.warranty_details}</p></span>
          </div>
          <div id='bottom-details'>
            <span className='ispec' id='components-different'><p>Components may vary from images shown.</p></span>
            <button className="add-to-cart-button" onClick={() => addToCart(item, image)}>Add to Cart</button>
          </div>
        </div>
      </div>
    );
  }
};

export default ItemDetail;